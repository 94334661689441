<template>
    <div>
        <header-slot/>
        <payments-list />
    </div>
</template>

<script>
import PaymentsList from '@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue'
export default {
    components: {PaymentsList}
}
</script>