<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refClientsList'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refClientsList"
          small
          no-provider-filtering
          :items="search"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- CLIENT NAME -->
          <template v-slot:cell(client_name)="data">
            <router-link
              v-if="program == 1"
              :to="{
                name: 'bussiness-dashboard-clients',
                params: { idClient: data.item.account_id },
              }"
              :class="textLink"
              target="_blank"
            >{{ data.item.client_name }}</router-link>
            <router-link
              v-if="program == 4"
              :to="{
                name: 'debt-solution-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
            >{{ data.item.client_name }}
            </router-link>
            <template v-if="program == 3">
              <router-link
                v-if="moduleId != 23"
                :class="[textLink]"
                :to="{
                  name: 'credit-experts-dashboard',
                  params: { idClient: data.item.account_id },
                }"
                target="_blank"
              >{{ data.item.client_name }}</router-link>
              <view-client-details
                v-else
                :client-information="data.item"
              />
            </template>
            <!-- <router-link
              v-if="program == 3"
              :to="{
                name: 'credit-experts-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
              >{{ data.item.client_name }}</router-link
            > -->
            <router-link
              v-if="program == 2"
              :to="{
                name: 'boost-credit-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
            >{{ data.item.client_name }}</router-link>
            <router-link
              v-if="program == 5"
              :to="{
                name: 'tax-research-client-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
            >{{ data.item.client_name }}
            </router-link>

            <router-link
              v-if="program == 9"
              :class="textLink"
              :to="`/ti/clients/account/${data.item.account_id}`"
              target="_blank"
            >{{ data.item.client_name }}</router-link>

            <router-link
              v-if="program == 8"
              :class="[textLink]"
              :to="{
                name: 'bookeeping-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
            >{{ data.item.client_name }}</router-link>
            <router-link
              v-if="program == 6"
              :class="[textLink]"
              :to="{
                name: 'claim-department-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
            >{{ data.item.client_name }}</router-link>
            <router-link
              v-if="program == 7"
              :class="[textLink]"
              :to="{
                name: 'specialists-client-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
            >{{ data.item.client_name }}</router-link>
          </template>

          <!-- STATUS -->
          <template
            v-slot:cell(status)="data"
          ><span
            :class="
              data.item.status == 'Approved' ? 'text-success' : 'text-danger'
            "
          >{{ data.item.status }}</span></template>

          <!-- PAYMENT -->
          <template v-slot:cell(payment)="data">{{
            data.item.payment | myMoney
          }}</template>

          <!-- AMOUNT -->
          <template v-slot:cell(amount)="data">
            <span
              v-if="validCellAmountDataUno(data)"
            >$ {{ data.item.amount | currencyZero }}</span>
            <span
              v-if="validCellAmountDataDos(data)"
            >( ${{ data.item.amount | currencyZero }})</span>
            <span
              v-if="parseFloat(data.item.sumref)>0 && ![10,11].includes(parseInt(data.item.type_t))"
              class="text-info"
            >
              (${{ (parseFloat(data.item.amount) -parseFloat(data.item.sumref)).toFixed(2) }})</span>
            <span v-if="data.item.pending_void_transactions>0 && ![10,11].includes(parseInt(data.item.type_t))"
              class="ml-1">

              <b-badge
                variant="warning"
                style="cursor: pointer"
                class="badge"
                :id="`pending-void-transaction-${data.item.transaction_id}`"
              >
                <tabler-icon
                  icon="ClockIcon"
                  size="18"
                  class="pending-void-transaction"
                />
              </b-badge>
              <b-tooltip
                :target="`pending-void-transaction-${data.item.transaction_id}`"
                triggers="hover"
                placement="top"
                variant="warning"
                title="Pending Void Transaction"/>
            </span>
          </template>

          <template #cell(settlement_date)="data">
            {{ data.item.settlement_date | myGlobalDay }}
          </template>
          <template v-slot:cell(action)="data">
            <feather-icon
              v-if="validVoidRefund(data)"
              icon="EyeIcon"
              class="text-primary cursor-pointer"
              size="15"
              @click="openVoidRefundInfoModal(data.item.transaction_id)"
            />
            <tabler-icon
              v-if="validVoidAuthorizeUno(data)"
              icon="CoinIcon"
              class="text-danger cursor-pointer"
              size="15"
              @click="openVoidRefundModal(data.item, 1)"
            />
            <feather-icon
              v-if="validVoidAuthorizeDos(data)"
              icon="ClockIcon"
              class="text-success cursor-pointer"
              size="15"
              @click="openVoidRefundModal(data.item, 2)"
            />
          </template>

          <!-- CREATED AT -->
          <template
            v-slot:cell(created_at)="data"
          >{{ data.item.created_at | myGlobal }}
            {{ data.item.created_at | myHourTime }}</template>
        </b-table>
      </template>
    </filter-slot>
    <void-refund-modal
      v-if="voidRefundModalOn"
      :void-refund-data="voidRefundData"
      :type-void-refund="typeVoidRefund"
      @refresh="$refs['refClientsList'].refresh()"
      @close="closeVoidRefundModal"
    />
    <void-refund-info
      v-if="voidrefundInfoModalOn"
      :transaction-id="transactionId"
      @close="closeVoidRefundInfoModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Fields from '@/views/commons/components/payments/views/data/fields.clients.data';
import filtersData from '@/views/commons/components/payments/views/data/filters.clients.data';
import GlobalService from '@/service/global';
import VoidRefundModal from '@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundModal.vue';
import VoidRefundInfo from '@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundInfoModal.vue';
import PaymentsService from '@/views/commons/components/payments/services/Payments.service';
import ViewClientDetails from '@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue';

export default {
  // If you reuse this component, values,filters and fields are hardcoded
  components: {
    VoidRefundModal,
    VoidRefundInfo,
    ViewClientDetails,
  },
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'created_at',
      sortDesc: true,
      fields: Fields,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      // perPageOptions: [10, 25, 50, 100],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Client...',
        model: '',
      },
      filter: filtersData,
      transactionId: null,
      advisors: [],
      dataVoid: [],
      voidRefundData: {},
      voidRefundModalOn: false,
      typeVoidRefund: null,
      voidrefundInfoModalOn: false,
      typeSelect: [
        { id: '0', text: 'All' },
        { id: '7', text: 'Charge' },
        { id: '2', text: 'Monthly Payment' },
        { id: '14', text: 'Zero Payment' },
        { id: '6', text: 'Others' },
        { id: '10', text: 'Void' },
        { id: '11', text: 'Refund' },
      ],
      resultSelect: [
        { id: '0', text: 'All' },
        { id: '1', text: 'Approved' },
        { id: '2', text: 'Declined' },
        { id: '3', text: 'Error' },
        { id: '4', text: 'Held for Review' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
    changeModule: {
      get() {
        const nameModules = {
          'debt-solution': 5,
          'credit-experts': 6,
          'boost-credit': 7,
          business: 3,
          'pay-module': 12,
        };
        const modules = {
          id: this.moduleId,
          name: nameModules[this.moduleId] || null,
        };
        return modules;
      },
    },
    visibleFields() {
      return this.fields.filter(field => field.visible);
    },
    modul() {
      return this.moduleId === 12 ? this.$route.meta.moduleTab : this.moduleId;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    program() {
      if(this.modul == 28) return 7;

      return this.convertoModuleToProgramC(this.modul);
    },
  },
  async created() {
    await Promise.all([this.getDataAdvisor()]);

    this.filter[2].options = this.typeSelect;
    this.filter[3].options = this.resultSelect;
    this.filter[4].options = this.advisors;
  },
  methods: {
    openVoidRefundInfoModal(transactionId) {
      this.transactionId = transactionId;
      this.voidrefundInfoModalOn = true;
    },

    closeVoidRefundInfoModal() {
      this.voidrefundInfoModalOn = false;
    },

    openVoidRefundModal(data, type) {
      this.typeVoidRefund = type;
      this.voidRefundData = {
        transaction_id: data.transaction_id,
        merchant: data.merchant,
        amount: parseFloat(data.sumref) > 0 ? (data.amount - parseFloat(data.sumref)).toFixed(2) : parseFloat(data.amount.replace(/,/g, '')),
        client_name: data.client_name,
        settlement_date: data.settlement_date,
      };
      this.voidRefundModalOn = true;
    },

    closeVoidRefundModal() {
      this.voidRefundModalOn = false;
    },

    async getDataAdvisor() {
      if (this.modul == 3 || this.modul == 12) {
        const res = await GlobalService.getOwners({
          modul: 3,
          body: {
            roles: '[1,3,2]',
            type: 0,
          },
        });
        this.advisors = res.data;
        const res2 = await GlobalService.getOwners({
          modul: 12,
          body: {
            roles: '[1,3,2]',
            type: 1,
          },
        });
        this.advisors = this.advisors.concat(res2.data);
        this.advisors = this.advisors.sort((a, b) => (a.user_name > b.user_name ? 1 : -1));
      } else if (this.modul == 8) {
        const res = await GlobalService.getOwners({
          modul: this.modul,
          body: {
            roles: '[1,3]',
            type: 0,
          },
        });
        this.advisors = res.data;
      } else {
        const res = await GlobalService.getOwners({
          modul: this.modul,
          body: {
            roles: '[1, 2, 3, 5]',
            type: 0,
          },
        });
        this.advisors = res.data;
      }
    },

    validCellAmountDataUno(data) {
      return data.item.type_t != 10 && data.item.type_t != 11;
    },
    validCellAmountDataDos(data) {
      return [10, 11].includes(data.item.type_t);
    },
    validVoidRefund(data) {
      return [10, 11].includes(data.item.type_t);
    },
    validVoidAuthorizeUno(data) {
      return (
        data.item.type_t != 10
        && data.item.type_t != 11
        && data.item.void == 1
        && data.item.w_card == 1
        && this.currentUser.role_id == 1
        && data.item.status == 'Approved'
        && parseFloat(data.item.amount) > parseFloat(data.item.sumref)
        && data.item.pending_void_transactions < 1
      );
    },
    validVoidAuthorizeDos(data) {
      return (
        data.item.type_t != 10
        && data.item.type_t != 11
        && data.item.void == 0
        && data.item.refund == 1
        && data.item.w_card == 1
        && this.currentUser.role_id == 1
        && data.item.status == 'Approved'
        && parseFloat(data.item.amount) > parseFloat(data.item.sumref)
      );
    },
    isAdvisorBk() {
      if (
        [6, 7, 14].includes(this.moduleId)
        && this.currentUser.role_id === 3
      ) {
        return this.currentUser.user_id;
      }
      return this.filter[4].model;
    },
    async search() {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          status: this.filter[3].model,
          advisor: this.isAdvisorBk(),
          from: this.filter[0].model,
          to: this.filter[1].model,
          modul: this.program,
          type: this.filter[2].model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
        };
        const res = await PaymentsService.getAllPaymentsClients(obj);
        if (res.status === 200) {
          this.clients = res.data.data;
          this.startPage = res.data.from;
          this.paginate.currentPage = res.data.current_page;
          this.paginate.perPage = res.data.per_page;
          this.totalRows = res.data.total;
          this.toPage = res.data.to;
          return res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.tdClass {
  font-size: 14px;
  padding: 7px 10px !important;
}.pending-void-transaction{
  color:white;
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
