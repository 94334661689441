export default[
    
    {
        key:"client_name",
        label:"Name",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"account",
        label:"Account",
        visible:true,
        tdClass: 'tdClass',
    },
    {
        key:"type",
        label:"Type",
        visible:true,
        tdClass:'tdClass',
        
    },
    {
        key:"concept",
        label:"Concept",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"amount",
        label:"Amount",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"action",
        label:"",
        visible:true,
        tdClass:'tdClass',
     },
    {
        key:"transaction_id",
        label:"Transaction",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"payment",
        label:"Payment",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"status",
        label:"Result",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"card_number",
        label:"Credit Card",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"user_name",
        label:"User",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"settlement_date",
        label:"Creation Date",
        visible:true,
        tdClass:'tdClass',
    },
    
]