export default [
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
        cols: 6
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
        cols: 6
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Type",
        model: null,
        options: [
            { id: "0", text: "All" },
            { id: "7", text: "Charge" },
            { id: "2", text: "Monthly Payment" },
            { id: "14", text: "Zero Payment" },
            { id: "10", text: "Void" },
            { id: "11", text: "Refund" },
        ],
        reduce: "id",
        selectText: "text",
        cols: 12
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Result",
        model: null,
        options: [
            { id: "0", text: "All" },
            { id: "1", text: "Approved" },
            { id: "2", text: "Declined" },
            { id: "3", text: "Error" },
            { id: "4", text: "Held for Review" },
        ],
        reduce: "id",
        selectText: "text",
        cols: 12
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Advisor",
        model: null,
        options: [],
        reduce: "id",
        selectText: "user_name",
        cols: 12
    }
]